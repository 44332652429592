// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layout-houses-tsx": () => import("./../../../src/layout/Houses.tsx" /* webpackChunkName: "component---src-layout-houses-tsx" */),
  "component---src-layout-project-tsx": () => import("./../../../src/layout/Project.tsx" /* webpackChunkName: "component---src-layout-project-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cad-tsx": () => import("./../../../src/pages/cad.tsx" /* webpackChunkName: "component---src-pages-cad-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-example-3-d-tsx": () => import("./../../../src/pages/example3d.tsx" /* webpackChunkName: "component---src-pages-example-3-d-tsx" */),
  "component---src-pages-example-viza-tsx": () => import("./../../../src/pages/exampleViza.tsx" /* webpackChunkName: "component---src-pages-example-viza-tsx" */),
  "component---src-pages-exapmle-architect-tsx": () => import("./../../../src/pages/exapmleArchitect.tsx" /* webpackChunkName: "component---src-pages-exapmle-architect-tsx" */),
  "component---src-pages-exapmle-tsx": () => import("./../../../src/pages/exapmle.tsx" /* webpackChunkName: "component---src-pages-exapmle-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-houses-tsx": () => import("./../../../src/pages/houses.tsx" /* webpackChunkName: "component---src-pages-houses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-measure-tsx": () => import("./../../../src/pages/measure.tsx" /* webpackChunkName: "component---src-pages-measure-tsx" */),
  "component---src-pages-new-design-house-tsx": () => import("./../../../src/pages/new/design-house.tsx" /* webpackChunkName: "component---src-pages-new-design-house-tsx" */),
  "component---src-pages-new-design-interior-1-tsx": () => import("./../../../src/pages/new/design_interior_1.tsx" /* webpackChunkName: "component---src-pages-new-design-interior-1-tsx" */),
  "component---src-pages-new-design-za-99-a-tsx": () => import("./../../../src/pages/new/design-za-99-a.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-a-tsx" */),
  "component---src-pages-new-design-za-99-b-tsx": () => import("./../../../src/pages/new/design-za-99-b.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-b-tsx" */),
  "component---src-pages-new-design-za-99-c-tsx": () => import("./../../../src/pages/new/design-za-99-c.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-c-tsx" */),
  "component---src-pages-new-design-za-99-d-tsx": () => import("./../../../src/pages/new/design-za-99-d.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-d-tsx" */),
  "component---src-pages-new-design-za-99-e-tsx": () => import("./../../../src/pages/new/design-za-99-e.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-e-tsx" */),
  "component---src-pages-new-design-za-99-f-tsx": () => import("./../../../src/pages/new/design-za-99-f.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-f-tsx" */),
  "component---src-pages-new-design-za-99-g-tsx": () => import("./../../../src/pages/new/design-za-99-g.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-g-tsx" */),
  "component---src-pages-new-design-za-99-h-tsx": () => import("./../../../src/pages/new/design-za-99-h.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-h-tsx" */),
  "component---src-pages-new-design-za-99-i-tsx": () => import("./../../../src/pages/new/design-za-99-i.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-i-tsx" */),
  "component---src-pages-new-design-za-99-j-tsx": () => import("./../../../src/pages/new/design-za-99-j.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-j-tsx" */),
  "component---src-pages-new-design-za-99-k-tsx": () => import("./../../../src/pages/new/design-za-99-k.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-k-tsx" */),
  "component---src-pages-new-design-za-99-l-tsx": () => import("./../../../src/pages/new/design-za-99-l.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-l-tsx" */),
  "component---src-pages-new-design-za-99-more-tsx": () => import("./../../../src/pages/new/design-za-99-more.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-more-tsx" */),
  "component---src-pages-new-design-za-99-s-tsx": () => import("./../../../src/pages/new/design-za-99-s.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-s-tsx" */),
  "component---src-pages-new-design-za-99-tsx": () => import("./../../../src/pages/new/design-za-99.tsx" /* webpackChunkName: "component---src-pages-new-design-za-99-tsx" */),
  "component---src-pages-new-posadka-3-d-tsx": () => import("./../../../src/pages/new/posadka3d.tsx" /* webpackChunkName: "component---src-pages-new-posadka-3-d-tsx" */),
  "component---src-pages-new-posadka-house-tsx": () => import("./../../../src/pages/new/posadka-house.tsx" /* webpackChunkName: "component---src-pages-new-posadka-house-tsx" */),
  "component---src-pages-new-promo-3-d-tsx": () => import("./../../../src/pages/new/promo-3d.tsx" /* webpackChunkName: "component---src-pages-new-promo-3-d-tsx" */),
  "component---src-pages-new-promo-3-dnew-tsx": () => import("./../../../src/pages/new/promo-3dnew.tsx" /* webpackChunkName: "component---src-pages-new-promo-3-dnew-tsx" */),
  "component---src-pages-new-promo-color-tsx": () => import("./../../../src/pages/new/promo-color.tsx" /* webpackChunkName: "component---src-pages-new-promo-color-tsx" */),
  "component---src-pages-new-promo-f-tsx": () => import("./../../../src/pages/new/promo-f.tsx" /* webpackChunkName: "component---src-pages-new-promo-f-tsx" */),
  "component---src-pages-new-promo-visualization-tsx": () => import("./../../../src/pages/new/promo-visualization.tsx" /* webpackChunkName: "component---src-pages-new-promo-visualization-tsx" */),
  "component---src-pages-new-technical-interior-design-tsx": () => import("./../../../src/pages/new/technical-interior-design.tsx" /* webpackChunkName: "component---src-pages-new-technical-interior-design-tsx" */),
  "component---src-pages-new-tsx": () => import("./../../../src/pages/new.tsx" /* webpackChunkName: "component---src-pages-new-tsx" */),
  "component---src-pages-new-validation-page-tsx": () => import("./../../../src/pages/new/validationPage.tsx" /* webpackChunkName: "component---src-pages-new-validation-page-tsx" */),
  "component---src-pages-posadka-short-tsx": () => import("./../../../src/pages/posadkaShort.tsx" /* webpackChunkName: "component---src-pages-posadka-short-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-promo-tsx": () => import("./../../../src/pages/promo.tsx" /* webpackChunkName: "component---src-pages-promo-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-style-tsx": () => import("./../../../src/pages/style.tsx" /* webpackChunkName: "component---src-pages-style-tsx" */),
  "component---src-pages-wiki-tsx": () => import("./../../../src/pages/wiki.tsx" /* webpackChunkName: "component---src-pages-wiki-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */)
}

