import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './src/i18n/config'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

const wrapPageElement = ({ element, props }) => {
    const addResources = (pc, language) => {
        if (pc && pc.localeResources) {
            if (!i18n.hasResourceBundle(language, 'translations')) {
                i18n.addResourceBundle(language, 'translations', {
                    ...pc.localeResources,
                })
            }
        }
    }
    const pageContext = props.pageContext
    if (pageContext) {
        const currentLanguage = pageContext.locale
        if (currentLanguage && currentLanguage !== i18n.language) {
            addResources(pageContext, currentLanguage)
            i18n.changeLanguage(currentLanguage)
        }
    }

    return (
        <QueryParamProvider adapter={ReachAdapter}>
            <I18nextProvider i18n={i18n} {...props}>
                {element}
            </I18nextProvider>
        </QueryParamProvider>
    )
}

export default wrapPageElement
